.login-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__wrapper {
  width: 500px;
  margin: 0 auto;
}
.login__group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
